import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import App from "./App";
import Faq from "./Faq";

function Main() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <App />
        </Route>
        <Route path="/faq">
          <Faq />
        </Route>
      </Switch>
    </Router>
  );
}

export default Main;
