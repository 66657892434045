import React from "react";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>It's Ranch 99.</h1>
        <a className="App-link" href="/faq">
          FAQ
        </a>
      </header>
    </div>
  );
}

export default App;
