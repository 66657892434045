import React from "react";
import "./App.css";

function Faq() {
  return (
    <div className="Faq">
      <header className="Faq-header">
        <p>Why does this website exist?</p>
        <p className="Faq-answer">
          A lot of free time and the desire to win arguments.
        </p>
      </header>
      <header className="Faq-header">
        <p>But doesn't the official website say it's 99 Ranch?</p>
        <p className="Faq-answer">
          You're the one looking for an answer on the internet. I know it in my
          heart.
        </p>
      </header>
      <header className="Faq-header">
        <p>Why should I trust you?</p>
        <p className="Faq-answer">
          I spent all this time making a website, why would I lie?
        </p>
      </header>
      <header className="Faq-header">
        <a className="App-link" href="/">
            You know the true answer in your heart. 
        </a>
      </header>
    </div>
  );
}

export default Faq;
